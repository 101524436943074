import Head from 'next/head';
import React from 'react';

import Layout from '@/components/Layout';

const NotFound: React.FC = () => {
  return (
    <Layout>
      <Head>
        <title>404 Not Found</title>
      </Head>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh',
        }}
      >
        <h1>Página não encontrada.</h1>
      </div>
    </Layout>
  );
};

export default NotFound;
